import $ from 'jquery';

$(document).ready(() => {
  $('.bulk-window-enable-input').change(function() {
    refreshVisanoScoreMonitoringForm();
  });

  $('.max-rpm-enable-input').change(function() {
    refreshVisanoScoreMonitoringForm();
  });

  $('.configure-client-input').change(function() {
    refreshVisanoScoreMonitoringForm();
  });

  refreshVisanoScoreMonitoringForm();
});

function refreshVisanoScoreMonitoringForm() {
  $('.client-settings-form').each(function() {
    let configureClient = $(this).find('.configure-client-input')[0].checked;

    $(this).find("input:not(.configure-client-input), select").prop('disabled', !configureClient);

    if (configureClient) {
      let enableBulkWindow = $(this).find('.bulk-window-enable-input')[0].checked;
      let enableMaxRpm = $(this).find('.max-rpm-enable-input')[0].checked;

      $(this).find('.bulk-window-select').prop('disabled', !enableBulkWindow);
      $(this).find('.max-rpm-text').prop('disabled', !enableMaxRpm);
    }
  });
}
