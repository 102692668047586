import $ from 'jquery';

$(() => {
  $('form').on('click', '.remove_fields', function(event) {
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('tr').hide();
    event.preventDefault();
  });


  $('form').on('click', '.add_fields', function(event) {
    const time = new Date().getTime();
    const regexp = new RegExp($(this).data('id'), 'g');
    $(event.target)
      .parent()
      .children('table')
      .first()
      .children('tbody')
      .first()
      .children('tr:last')
      .after($(this).data('fields').replace(regexp, time));

    event.preventDefault();
  });

  $('form').on('click', '#display_layout_configuration_modal', function() {
    $('input#configuring_report_layout').val('true');
  });

  $('form').on('click', '#close_layout_configuration_modal', function() {
    $('input#configuring_report_layout').val('false');
  });

  $('form').on('click', '#display_bhcc_configuration_modal', function() {
    $('input#configuring_bhcc_settings').val('true');
  });

  $('form').on('click', '#close_bhcc_configuration_modal', function() {
    $('input#configuring_bhcc_settings').val('false');
  });

  $(document).on('click', '#licensee_search_by', function(event){
    var child = this.firstChild;
    var placeholder = child.text.trim().slice(10);
    $(this).addClass('licensee-search-by').siblings().removeClass('licensee-search-by');
    document.getElementById('licensee_filter_data').placeholder = placeholder;
    document.getElementById('licensee_filter_search_by').value = placeholder;
    event.preventDefault();
  });

  $( document ).ready(function() {

    $("#dea_list").select2({
      createTag: function (params) {
        var term = $.trim(params.term).toUpperCase();

        if (term === '') {
          return null;
        }

        return {
          id: term,
          text: term,
          newTag: true
        }
      },
      tags: true,
      tokenSeparators: [',', ' ', '\n'],
      placeholder: "add dea(s)"
    });

    $("#npi_list").select2({
      tags: true,
      tokenSeparators: [',', ' ', '\n'],
      placeholder: "add npi(s)"
    });

    $('#care_navigation_modal_body .copy-button').on('click', function(e) {
      var selectedItems = $(e.currentTarget.parentElement.querySelector('select')).select2('data');
      var stringToCopy = '';
      var firstElement = true;
      selectedItems.forEach(function(item) {
        if (firstElement) {
          firstElement = false;
        } else {
          stringToCopy += ', ';
        }
        stringToCopy += item.text;
      });

      navigator.clipboard.writeText(stringToCopy)

      e.preventDefault();

    });

    $('#care_navigation_modal').on('hide.bs.modal', function(e) {
      var dea_list = e.currentTarget.querySelector('#dea_list');
      var npi_list = e.currentTarget.querySelector('#npi_list');

      $(dea_list).find('option').prop('selected', true);
      $(npi_list).find('option').prop('selected', true);

      resetList(dea_list);
      resetList(npi_list);
    });

    $('#care_navigation_modal_footer #save_care_navigation_modal_button').on('click', function(e) {

      var dea_list = e.currentTarget.parentElement.parentElement.querySelector('#dea_list');
      var npi_list = e.currentTarget.parentElement.parentElement.querySelector('#npi_list');
      var selectedDeaItems = $(dea_list).select2('data');
      var selectedNpiItems = $(npi_list).select2('data');
      var hasValidationError = false;

      selectedNpiItems.forEach(function(item) {
        if (validateNpi(item) === '') {
          var text = item.text;
          $(`li[title=${text}]`, npi_list.nextSibling).css('background-color', 'pink');
          $('#validation-fail-text').show();
          hasValidationError = true;
        }
        else {
          $(`li[title=${item.text}]`, npi_list.nextSibling).css('background-color', '#e4e4e4');
        }
      });

      selectedDeaItems.forEach(function(item) {
        if (!validateDea(item)) {
          var text = item.text;

          $(`li[title=${text}]`, dea_list.nextSibling).css('background-color', 'pink');
          $('#validation-fail-text').show();
          hasValidationError = true;
        }
        else {
          $(`li[title=${item.text}]`, dea_list.nextSibling).css('background-color', '#e4e4e4');
        }
      });

      if (!hasValidationError) {
        $('#validation-fail-text').hide();
      }
      else {
        e.preventDefault();
      }

    });

  } )

});

function validateNpi(npiItem) {
  return npiItem.text.match("^[0-9]{10}$") ? npiItem.text : '';
}

function validateDea(deaItem) {
  deaItem = deaItem.text;
  let dea_regular_expression =  "[A-z]{1}[A-z9]{1}[0-9]{7}$";
  let dea_regular_expression_with_intern =  "[A-z]{1}[A-z9]{1}[0-9]{7}[-][A-z\\d]*";
  if (deaItem.match(dea_regular_expression) || deaItem.match(dea_regular_expression_with_intern)) {

    var result = parseInt(deaItem[2]) + parseInt(deaItem[4]) + parseInt(deaItem[6]) +
        ((parseInt(deaItem[3]) + parseInt(deaItem[5]) + parseInt(deaItem[7])) * 2)

    return (result % 10 === parseInt(deaItem[8]))
  }
  else {
    return false;
  }
}

function resetList(list) {
  var startingSelectedItems = $(list).select2('data');
  var selectedItems = [];

  startingSelectedItems.forEach(function(item) {
    if (item.title === 'preselected') {
      selectedItems.push(item);
    }
  });

  $(list).empty();


  selectedItems.forEach(function(item) {
    $('<option>',
      {
        title: 'preselected',
        id: item.text,
        text: item.text,
        selected: "selected"
      }).select2({
      dropdownParent: $(item.id )
    }).appendTo(list);
  });
}