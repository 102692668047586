import $ from 'jquery';

var request_destination_settings = (function($){
  var msg_window = 'p#request_destination_service_settings_message';
  var bs_switch_pmpi = 'input#request_destination_service_settings_get_from_pmpi';
  var field_endpoint = '#request_destination_service_settings_endpoint';
  var modal_window = 'div#request_destination_service_settings';

  var bs_switch_heimdall = 'input#request_destination_service_settings_manage_credentials_via_heimdall';
  var field_password = 'input#request_destination_service_settings_password';
  var field_username = 'input#request_destination_service_settings_username';

  var bs_switch_provider_auth_required = 'input#request_destination_service_settings_provider_auth_required';
  var selection_provider_auth_version = '#request_destination_service_settings_provider_auth_version';

  var flipSwitchManageViainterconnect = function(){
    var state = $(this).prop('checked');

    if(state === true){
      //user is switching to true.
      $(field_endpoint).prop('title',"Disabled because this is Managed Via Interconnect");
      $(field_endpoint).tooltip();
      $(field_endpoint).prop('disabled',true); //Disable endpoint field.

    }else{
      $(field_endpoint).prop('title',"");
      $(field_endpoint).tooltip('destroy');
      $(field_endpoint).prop('disabled',false); //Enable endpoint field.
    }
  };

  var flipSwitchHeimdall = function() {
    var state = $(this).prop('checked');

    if(state === true){
      $(field_password).prop('disabled',true);
      $(field_username).prop('disabled',true);

    }else{
      $(field_password).prop('disabled',false);
      $(field_username).prop('disabled',false);
    }
  };

  var testAuthorizationService = function() {
    var request_destination_id = $(modal_window).data('trigger-by-id');
    $('#spinner').css('display','inline');
    var uri = 'request_destinations/'+request_destination_id+'/test_authorization_service';
    $.ajax({type:'GET', url: uri}).done(function(data) {
      MsgOk(data);
    }).fail(function(xhr) {
      if(xhr.status == 422){
        MsgError(xhr.responseText);
      }else{
        if(xhr.status == 401){
          alert('Reload page and try again.\nError Info: '+xhr.responseText+'');
          location.reload();
        }else{
          MsgError(xhr.responseText);
        }
      }
    }).always(function(){
      $('#spinner').css('display','none');
    });
  };

  var updateSettingsOnServer = function() {
    var request_destination_id = $(modal_window).data('trigger-by-id');
    var uri = 'request_destinations/'+request_destination_id+'/update_service_settings';
    $('#spinner').css('display','inline');

    var obj = {
      "endpoint" : $(field_endpoint).val(),
      "manage_via_pmp_interconnect" :  $(bs_switch_pmpi).prop('checked'),
      "manage_credentials_via_heimdall" : $(bs_switch_heimdall).prop('checked'),
      "is_provider_auth_required" : $(bs_switch_provider_auth_required).prop('checked'),
      "provider_auth_version" : $(selection_provider_auth_version).val(),
      "username" : $(field_username).val(),
      "password" : $(field_password).val()
    };

    var json_obj = JSON.stringify(obj);

    $.ajax({
      type:'POST',
      url: uri,
      dataType: 'json',
      data: json_obj
    }).done(function(data) {
      MsgOk("Updated");
      setDisplayFields(data);
    }).fail(function(xhr) {
      if (xhr.status == 422) {
        MsgError(xhr.responseText);
      } else {
        if (xhr.status != 401) {
          alert('Unable to load settings.\nError Info: '+xhr.responseText+'');
        }
        location.reload();
      }
    }).always(function() {
      $('#spinner').css('display','none');
    });
  };

  var loadSettingsFromServer = function() {
    var request_destination_id = $(modal_window).data('trigger-by-id');
    var uri = 'request_destinations/'+request_destination_id+'/show_service_settings';
    $.ajax({type:'GET', url: uri}).done(function(data) {
      setDisplayFields(data);
    }).fail(function(xhr) {
      if(xhr.status != 401){
        alert('Unable to load settings.\nError Info: '+xhr.responseText+'');
      }
      location.reload();
    });
  }

  var setDisplayFields = function(data) {
    $(field_endpoint).val(data.endpoint);
    $(field_username).val(data.username);
    $(field_password).val(data.password);
    $(bs_switch_pmpi).prop('checked', data.manage_via_pmp_interconnect).change();
    $(bs_switch_heimdall).prop('checked', data.manage_credentials_via_heimdall).change();
    $(bs_switch_provider_auth_required).prop('checked', data.is_provider_auth_required).change();
    $(selection_provider_auth_version).val(data.provider_auth_version);
  };

  var MsgClear = function() {
    $(msg_window).removeClass('text-success').removeClass('text-danger').text('');
  };

  var MsgOk = function(msg){
    MsgClear();
    $(msg_window).addClass('text-success').text(msg);
  };

  var MsgError = function(msg){
    MsgClear();
    $(msg_window).addClass('text-danger').text(msg);
  };

  var init = function() {
    $(bs_switch_pmpi).bootstrapToggle({
      'on': 'On',
      'off': 'Off',
      'labelText': '',
      'onstyle': 'primary',
    });

    $(bs_switch_heimdall).bootstrapToggle({
      'on': 'On',
      'off': 'Off',
      'labelText': '',
      'onstyle': 'primary',
    });

    $(bs_switch_provider_auth_required).bootstrapToggle({
      'on': 'On',
      'off': 'Off',
      'labelText': '',
      'onstyle': 'primary',
    });

    //Add the request_destination id to the modal
    $('[data-toggle="modal"]').on('click', function() {
      var current_item = $(this).data('request-destination-id');
      $(modal_window).data('trigger-by-id', current_item);
      MsgClear();

      // should prevent the auto complete from sometimes putting credentials in these fields before we load from the server.
      $(field_username).val('');
      $(field_password).val('');
    });

    $('#request_destination_service_settings').on('show.bs.modal', loadSettingsFromServer);
    $(bs_switch_pmpi).on('change', flipSwitchManageViainterconnect);
    $(bs_switch_heimdall).on('change', flipSwitchHeimdall);

    $('button#request_destination_service_settings_submit').on('click', updateSettingsOnServer);

    $('button#request_destination_service_settings_test_service').on('click', testAuthorizationService);
  };

  return {
    init: init
  }
})($);

$(function() {
  request_destination_settings.init();

  var state_codes = [
    "AA","AE","AK","AL","AP","AR","AS","AZ","CA","CO","CT","DC","DE","FL","FM","GA","GU",
    "HI","IA","ID","IL","IN","KS","KY","LA","MA","MD","ME","MH","MI","MN","MO","MP","MS",
    "MT","NC","ND","NE","NH","NJ","NM","NV","NY","OH","OK","OR","PA","PR","PW","RI","SC",
    "SD","TN","TX","UT","VA","VI","VT","WA","WI","WV","WY", "TS", "TT", "TZ"
  ];

  $(".js-do-state-code-check").each(function() {
    var row = $(this);
    var row_id = row.data('id');
    var el_request_name = $('input[data-id="request-name-'+ row_id+'"]');
    var el_is_pmp = $('input[data-id="is-pmp-'+ row_id+'"]');

    if(el_is_pmp.prop('checked') === true){
      if(state_codes.includes( el_request_name.val() )){
        el_request_name.removeClass('field_with_errors');
        el_request_name.data('tooltip',false);
      }else{
        el_request_name.addClass('field_with_errors');
        el_request_name.attr('title',"If this is a PMP then it's request name must be a State Code.");
        el_request_name.tooltip();
      }
    }
  });

  $('[name="update-request-destination"]').click(function() {
    $('[name="request_destination[active]"]').prop({ "disabled":false });
    $('[name="request_destination[is_pmp]').prop({ "disabled":false });
  });
});
