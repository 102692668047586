import $ from 'jquery';

$(() => {
  $('#report_request_pick_list').modal('hide');

  $(document).on('click', '#show_details', function(e){
    e.preventDefault();

    var report_id_type = $("input:radio[name='report[report_id_type]']:checked").val();
    var report_id = $('input[name="report[report_id]"]').val();
    var show_pdf = $('#report_show_pdf_button:checked').length;
    var do_not_filter_picklist = $('#report_do_not_filter_picklist:checked').length;

    // Submit Imcomplete Form to show validation flash messages
    if (report_id === '' || report_id_type === undefined) {
      $('#view_report_form').submit();
      return;
    }

    var params = 'report_id_type=' + report_id_type + '&report_id=' + report_id;
    var url = '/view_narx_care_report/historical_report_metadata?' + params;

    $.get( url, function(data) {
      process_historical_report(data, report_id_type, report_id, show_pdf)
    }).fail(function( data ) {
      console.log('Report not found under historical reports service'); // eslint-disable-line no-console
      console.log(data); // eslint-disable-line no-console

      var picklist_url = '/view_narx_care_report/pick_list?' + params;

      $.get( picklist_url, function( data ) {
        process_pick_list( report_id_type, report_id, show_pdf, do_not_filter_picklist, data );
      }).fail(function( data ) {
        console.log('Error retrieving Report Request Pick List'); // eslint-disable-line no-console
        console.log(data); // eslint-disable-line no-console
        process_pick_list( report_id_type, report_id, show_pdf, do_not_filter_picklist, data );
      });
    });
  });

  function reset_historical_reports_modal() {
    $('.modal_data').remove();
    $('#request_details').hide();
    $('#show_report').data('source', 'external');
    $('#show_report').show();
    $('#show_report_xml').show();
    $('#show_report_xml').data('source', 'external');
    $('#request_errors').hide();
    $('#historical_request_details').show()
  }

  function process_historical_report(data, report_id_type, report_id, show_pdf) {
    console.log(report_id_type, report_id, show_pdf) // eslint-disable-line no-console
    reset_historical_reports_modal();

    $('#report_request_received_at').text(data.report_details.ts_gateway_rendered);
    $('#report_request_type').text(data.report_details.template_gateway_rendered);
    $('#report_request_template').text(data.report_details.template_gateway_rendered);

    var facility = '<tr class="modal_data"><td>' + data.facility.name + '</td>';
    facility += '<td>' + data.facility.identifier_type + '</td>';
    facility += '<td>' + data.facility.identifier + '</td>';

    $('#report_request_facility tr:last').after(facility);

    var requester = '<tr class="modal_data"><td>' + data.requester.name + '</td>';
    requester += '<td>' + data.requester.identifier_type + '</td>';
    requester += '<td>' + data.requester.identifier + '</td>';

    $('#report_request_requester tr:last').after(requester);

    if (data.licensee.name) {
      var licensee = '<tr class="modal_data"><td>' + data.licensee.name + '</td>';
      licensee += '<td>' + data.licensee.area_of_business + '</td>';
      licensee += '<td>' + data.licensee.address1 + '</td>';
      licensee += '<td>' + data.licensee.address2 + '</td>';
      licensee += '<td>' + data.licensee.city + '</td>';
      licensee += '<td>' + data.licensee.state_code + '</td>';
      licensee += '<td>' + data.licensee.zip + '</td>';
      licensee += '<td>' + data.licensee.website_url + '</td></tr>';
      $('#report_request_licensee tr:last').after(licensee);
    } else {
      var notFound = '<tr class="modal-data"><td colspan="8">Licensee Not Found</td></tr>';
      $('#report_request_licensee tr:last').after(notFound);
    }

    $('#report_request_pick_list').modal('show');
  }

  $(document).on('click', '#show_report, #show_report_xml', function(e){
    var button = e.target;
    var source = $(button).data('source');
    var request_id, report_id_type;

    if (source === 'external') {
      request_id = $('input[name="report[report_id]"]').val();
      report_id_type = $("input:radio[name='report[report_id_type]']:checked").val();
    } else {
      var row_selected = $('.request_selected');
      report_id_type = 'report_request_id';

      if (row_selected.length == 0) {
        alert('You must select a Report Request row to continue');
        return;
      }
      request_id = row_selected.attr('id').split('report_request_id_')[1];
    }

    $('#report_request_pick_list').modal('hide');
    var show_pdf = $('#report_show_pdf_button:checked').length;
    var do_not_filter_picklist = $('#report_do_not_filter_picklist:checked').length;

    show_report( button.id, report_id_type, request_id, show_pdf, do_not_filter_picklist, source );
  });

  $(document).on('click', '#report_requests tr', function(){
    $(this).toggleClass('request_selected').siblings().removeClass('request_selected');
  });
});

function process_pick_list( report_id_type, report_id, show_pdf_button, do_not_filter_picklist, data ) {
  reset_modal();

  if ( data.length == 0 && report_id_type == 's3_path') {
    show_report( 'show_report_xml', report_id_type, report_id, show_pdf_button, do_not_filter_picklist, 's3' );
    return;
  }

  if ( data.length == 0) {
    $('#pick_list_error').html('Report Request for <strong>' + report_id_type + ': ' + report_id + '</strong> not found.');
    $('#request_details').hide();
    $('#show_report').hide();
    $('#show_report_xml').hide();
    $('#request_errors').show();
  } else {
    $('#patient_request_received_at').text(data.patient_request.received_at);
    $('#patient_request_destinations').text(data.patient_request.destinations);

    var pr_provider = '<tr class="modal_data"><td>' + data.patient_request.provider.first_name + '</td>';
    pr_provider += '<td>' + data.patient_request.provider.last_name + '</td>';
    pr_provider += '<td>' + data.patient_request.provider.role + '</td>';
    pr_provider += '<td>' + data.patient_request.provider.city + '</td>';
    pr_provider += '<td>' + data.patient_request.provider.state + '</td></tr>';

    $('#patient_request_provider tr:last').after(pr_provider);

    var pr_patient = '<tr class="modal_data"><td>' + data.patient_request.patient.first_name + '</td>';
    pr_patient += '<td>' + data.patient_request.patient.last_name + '</td>';
    pr_patient += '<td>' + data.patient_request.patient.dob + '</td>';
    pr_patient += '<td>' + data.patient_request.patient.gender + '</td>';
    pr_patient += '<td>' + data.patient_request.patient.state + '</td></tr>';

    $('#patient_request_patient tr:last').after(pr_patient);

    data.report_requests.forEach(function(request){
      var rr = '<tr id="report_request_id_' + request.id + '" class="modal_data report_request"><td>' + request.id + '</td>';
      rr += '<td>' + request.provider.first_name + '</td>';
      rr += '<td>' + request.provider.last_name + '</td>';
      rr += '<td>' + request.provider.role + '</td>';
      rr += '<td>' + request.provider.city + '</td>';
      rr += '<td>' + request.provider.state + '</td>';
      rr += '<td>' + request.pick_list + '</td>';
      rr += '<td>' + request.received_at + '</td></tr>';

      $('#report_requests tr:last').after(rr);
    });
  }

  $('#report_request_pick_list').modal('show');
}

function reset_modal() {
  $('.modal_data').remove();
  $('#request_details').show();
  $('#show_report').show();
  $('#show_report_xml').show();
  $('#request_errors').hide();
  $('#show_report').data('source', 'local');
  $('#show_report_xml').data('source', 'local');
  $('#historical_request_details').hide()
}

function show_report( report_type, report_id_type, report_id, show_pdf_button, do_not_filter_picklist, source) {
  var report = report_type.slice(5);

  var params = 'report[report_id_type]=' + report_id_type + '&report[report_id]=' + report_id;
  params += '&report[show_pdf_button]=' + show_pdf_button + '&report[do_not_filter_picklist]=' + do_not_filter_picklist + '&report[source]=' + source;

  var url = '/view_narx_care_report/' + report + '?' + params;

  window.open(url);
}
