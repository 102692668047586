import $ from 'jquery';

function states() {
  // initialize the hidden field for states selected
  $('#states_list').val(JSON.stringify(window.gon.regional_states));

  // current state is red, selected states green
  const specificStyles = {};
  specificStyles[window.gon.current_state_code] = { fill: 'red' };

  window.gon.regional_states.forEach(state => {
    specificStyles[state] = { fill: 'green' };
  });

  $('#map').usmap({
    stateStyles: {
      fill: 'lightGray',
    },
    stateSpecificStyles: specificStyles,
    mouseover: function(event) {
      if (window.gon.regional_states_readonly) event.preventDefault();
    },
    click: function(event, data) {
      if (window.gon.regional_states_readonly) return;

      // don't allow current state to be selected
      if (data.name != window.gon.current_state_code) {
        // add green as a specific style or delete to toggle state highlight
        if (this.stateSpecificStyles[data.name]) {
          delete this.stateSpecificStyles[data.name];
        } else {
          this.stateSpecificStyles[data.name] = { fill: 'green' };
        }
      }

      // this could be more intelligent, but I'm just recalculating along the way
      const regionalStates = [];

      for (let key in this.stateSpecificStyles) {
        if (this.stateSpecificStyles[key]['fill'] != 'red') {
          regionalStates.push(key);
        }
      }

      $('#states_list').val(JSON.stringify(regionalStates))
    },
  });
}

$(() => {
  if (window.gon && window.gon.regional_states) {
    states();
  }
});

$(document).ready(() => {
  // assumes the checkboxes have the class "toggle"
  $('input[type="checkbox"].toogle').bootstrapToggle();

  if (window.gon && window.gon.regional_states) {
    states();
  }
});

$(() => {
  $('[data-ervive-form]').each(function() {
    const updatePath = $(this).data('ervive-form');

    $(this).find('[data-ervive-setting]').each(function () {
      let erviveSetting = $(this).data('ervive-setting');

      if (erviveSetting === 'DD' || erviveSetting === 'OTP' || erviveSetting === 'NFOD') {
        setupErviveData(erviveSetting, updatePath, this);
      } else {
        let enabledSpinner = $(this).find('.enabled-spinner');
        let checkbox = $(this).find('[name="enabled"]');

        checkbox.on('change', () => {
          const isEnabled = checkbox.is(':checked');

          enabledSpinner.show();
          $.ajax(updatePath, {method: 'PUT', data: { enabled: isEnabled, ervive_setting: erviveSetting }})
            .done(() => {
              enabledSpinner.hide();
            });
        });
      }
    });
  });
});

function setupErviveData(erviveSetting, updatePath, parentElement) {
  const $checkbox          = $(parentElement).find('[name="enabled"]');
  const $enabledSpinner    = $(parentElement).find('.enabled-spinner');
  const $textarea          = $(parentElement).find('[name="text"]');
  const $submitButton      = $(parentElement).find('.submit-button');
  const $changeButton      = $(parentElement).find('.change-button');
  const $detailsSection    = $(parentElement).find('[data-section="details"]');
  const $formSection       = $(parentElement).find('[data-section="form"]');
  const $formSpinner       = $(parentElement).find('.form-spinner');
  const $validationMessage = $(parentElement).find('.validation-message');
  const $textMessageStatic = $(parentElement).find('.text-message-static')[0];
  const $staticNfodHeading = $(parentElement).find('.text-message-nfod-heading')[0];
  const $staticNfodTooltip = $(parentElement).find('.text-message-nfod-tooltip')[0];
  const $staticNfodUrl     = $(parentElement).find('.text-message-nfod-url')[0];
  const $textNfodHeading   = $(parentElement).find('[name="nfod_heading"]')[0];
  const $textNfodTooltip   = $(parentElement).find('[name="nfod_tooltip"]')[0];
  const $textNfodUrl       = $(parentElement).find('[name="nfod_url"]')[0];
  // const stateCode          = updatePath.split("/")[2];
  const $textMessageNfod   = $(parentElement).find('.nfod-validation-msg');

  $checkbox.on('change', () => {
    const isEnabled = $checkbox.is(':checked');

    $validationMessage.hide();

    if (isEnabled) {
      $formSection.show();
    } else {
      $formSection.hide();
      $detailsSection.hide();
      $textarea.val('');
      $enabledSpinner.show();
      $.ajax(updatePath, {method: 'PUT', data: { enabled: false, text: '', ervive_setting: erviveSetting }})
        .done(() => {
          $enabledSpinner.hide();
        });
    }
  });

  $changeButton.on('click', () => {
    $validationMessage.hide();
    $detailsSection.hide();
    $formSection.show();
  });

  $submitButton.on('click', () => {
    const text = $textarea.val();
    const isNfod = erviveSetting === 'NFOD';
    var isNfodValid = true;
    var validationMsg = "";

    if (isNfod) {
      var nfodHeading = $textNfodHeading.value;
      var nfodTooltip = $textNfodTooltip.value;
      var nfodUrl     = $textNfodUrl.value;

      const reNfod1 = /.{4,}/gm;
      const reNfod2 = /.{4,}/gm;
      const reNfod3 = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;

      var nfod1value = reNfod1.exec(nfodHeading);
      var nfod2value = reNfod2.exec(nfodTooltip);
      var nfod3value = reNfod3.exec(nfodUrl);

      if (nfod1value === null) {
        validationMsg += 'Heading should be at least 4 characters.';
        isNfodValid = false;
      }

      if (nfod2value === null) {
        validationMsg += 'Tooltip should be at least 4 characters.';
        isNfodValid = false;
      }

      if (nfod3value === null) {
        validationMsg += 'URL is not valid.';
        isNfodValid = false;
      }

      if (!isNfodValid) {
        $validationMessage.show();
        $textMessageNfod[0].innerHTML = validationMsg;
        $textarea.focus();
        return;
      } else {
        $staticNfodHeading.textContent = nfodHeading;
        $staticNfodTooltip.textContent = nfodTooltip;
        $staticNfodUrl.textContent     = nfodUrl;
      }
    }

    if (text === '') {
      $validationMessage.show();
      $textarea.focus();
    } else {
      $submitButton.hide();
      $formSpinner.show();

      // if (!isNfod) $textMessageStatic.innerHTML = text;
      if (!isNfod) {
        $textMessageStatic.textContent = '';
        $textMessageStatic.appendChild(document.createTextNode(text));
      }

      var put_data = {
        enabled: true,
        text: text,
        nfod_heading: nfodHeading,
        nfod_tooltip: nfodTooltip,
        nfod_url: nfodUrl,
        ervive_setting: erviveSetting,
      }

      $.ajax(updatePath, { method: 'PUT', data: put_data })
        .done(() => {
          $formSpinner.hide();
          $submitButton.show();
          $validationMessage.hide();
          $detailsSection.show();
          $formSection.hide();
        });
    }
  });
}
