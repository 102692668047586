import $ from 'jquery';

const onStateSelect = (e) => {
  const stateCode = e.target.getAttribute('data-state-code');
  const { id } = e.params.data;

  $.ajax({
    type: 'POST',
    url: `/states/${stateCode}/allowable_location_states`,
    dataType: 'json',
    data: { allowable_state_id: parseInt(id) },
    success: function() {}
  })

  event.preventDefault();

  return false;
};

const onStateUnselecting = (e) => {
  const stateToRemove = e.params.args.data.id;
  const homeState = e.target.getAttribute('data-state-id');

  if (parseInt(stateToRemove) === parseInt(homeState)) {
    e.preventDefault();

    alert('You cannot remove home state');
  } else {
    return true;
  }
}

const onStateUnselect = (e) => {
  const stateCode = e.target.getAttribute('data-state-code');
  const { id } = e.params.data;

  $.ajax({
    type: 'DELETE',
    url: `/states/${stateCode}/allowable_location_states/${id}`,
    dataType: 'json',
    success: function() {}
  })

  event.preventDefault();

  return false;
}

const loadLocationStates = () => {
  const $eventSelect = $('.location-states')
  if (!$eventSelect.length) return

  $eventSelect.select2();

  $eventSelect.on('select2:select', onStateSelect)

  $eventSelect.on('select2:unselecting', onStateUnselecting)

  $eventSelect.on('select2:unselect', onStateUnselect)
};

$(() => {
  loadLocationStates();
});

$(document).ready(() => {
  loadLocationStates();
});
