import $ from 'jquery';

$(function() {
  var RADIO_SELECTOR = '[name="comm_status"]';
  var SECTION_SELECTOR = '#comm_status_reports';

  function renderReportsSection() {
    var val = $(RADIO_SELECTOR + ':checked').val();

    if (val === 'enabled') {
      $(SECTION_SELECTOR).show();
    } else {
      $(SECTION_SELECTOR).hide();
    }
  }

  $(document).on('change', RADIO_SELECTOR, function() {
    renderReportsSection();
  });

  $(document).ready(() => {
    renderReportsSection();
  });

  renderReportsSection();
});

