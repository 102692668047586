/* eslint-disable sort-imports */

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-datetime-picker/css/bootstrap-datetimepicker.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import 'datatables.net-bs/css/dataTables.bootstrap.css';
import 'bootstrap-toggle/css/bootstrap-toggle.css';
import 'vendor/tagmanager/tagmanager.scss';
import 'select2/dist/css/select2.css';

import 'styles/useredit.scss';
import 'styles/user_management.scss';
import 'styles/state_bhcc_settings.scss';
import 'styles/gateway_settings.scss';
import 'styles/licensees.scss';
import 'styles/pagination_filter.scss';
import 'styles/request_destinations.scss';
import 'styles/scaffolds.scss';
import 'styles/states.scss';
import 'styles/states_communication.scss';
import 'styles/view_permissions.scss';
import 'styles/application.scss';
import 'styles/performance_report.scss';
import 'styles/narx_care_reports_service.scss';
import 'styles/visano_score_monitoring.scss';
import 'styles/provider_authorization_tool.scss';

import $ from 'jquery';

import dt from 'datatables.net-bs';
import dtBootstrap from 'datatables.net-bs';

import 'tinymce/tinymce';

import 'tinymce/themes/silver/theme';
import 'tinymce/models/dom/model';
import 'tinymce/icons/default/icons';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/autolink/plugin';
import 'tinymce/plugins/directionality/plugin';
import 'tinymce/plugins/visualblocks/plugin';
import 'tinymce/plugins/wordcount/plugin';
import 'tinymce/plugins/help/plugin';
import 'tinymce/plugins/advlist/plugin';
import 'tinymce/plugins/insertdatetime/plugin';
import 'tinymce/plugins/nonbreaking/plugin';
import 'tinymce/plugins/anchor/plugin';
import 'tinymce/plugins/charmap/plugin';
import 'tinymce/plugins/pagebreak/plugin';
import 'tinymce/plugins/template/plugin';
import 'tinymce/plugins/media/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/fullscreen/plugin';
import 'tinymce/plugins/visualchars/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';

import 'tinymce/skins/content/default/content.css';
import 'tinymce/skins/content/default/content.min.css';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/skins/ui/oxide/skin.min.css';

import 'tinymce/icons/default/icons';

import 'bootstrap';
import 'bootstrap-datetime-picker';
import 'bootstrap-toggle';
import 'select2';
import 'vendor/jquery-ujs';
import 'vendor/tagmanager/tagmanager';
import 'vendor/us-map-1.0.1/jquery.usmap';
// import 'vendor/tinyMce';

import 'src/state_communication';
import 'src/licensees.js';
import 'src/states.js';
import 'src/request_destinations';
import 'src/web_service_users';
import 'src/narx_care_reports_service';
import 'src/visano_score_monitoring';
import 'src/restrict_request_by_location';
import { CheckBoxSingleSelect } from 'src/web_service_users';

import CodeMirror from 'codemirror'
window.CodeMirror = CodeMirror;
//require('style-loader!codemirror/theme/abcdef.css')

// initialize DataTables
dt(window, $);
dtBootstrap(window, $);

window.$ = $;
window.CheckBoxSingleSelect = CheckBoxSingleSelect;

var Application = (function () {
  var ajax_connection_count = 0;
  var animation_count = 0;
  $(document).ajaxSend(function(){
    ajax_connection_count = ajax_connection_count + 1;
  });
  $(document).ajaxComplete(function() {
    ajax_connection_count = ajax_connection_count - 1;
    if (ajax_connection_count < 0) { // just in case
      ajax_connection_count = 0;
    }
  });
  $(document).on('show hide close slide', function(event) {
    if (event.type === 'hide') {
      if (!$(event.target).is(":visible")) {
        animation_count = animation_count - 1;
      }
    }
    animation_count = animation_count + 1;
  });
  $(document).on('shown hidden closed slid', function() {
    animation_count = animation_count - 1;
    if (animation_count < 0) {
      animation_count = 0;
    }
  });
  function prevent_loading_me_in_ajax () {
    // If we load a partial that is rendering a full template, we will have more than one title tag...
    if ($("title").length > 1) {
      location.reload();
    }
  }
  function animation_queue_count() {
    var queue_count = 0;
    $("*").each(function() {
      queue_count = queue_count + $(this).queue("fx").length;
    });
    return queue_count;
  }
  function animating_elements_count() {
    return $(":animated").length;
  }
  function am_I_resting() {
    // He's not resting, he's pining for the fjords!
    var queue_count = animation_queue_count();
    if ((queue_count > 0) || (ajax_connection_count > 0) || (animation_count > 0) || (animating_elements_count() > 0)) {
      return false;
    } else {
      return true;
    }
  }
  function debug_resting() {
    const msg = "queue count: " + animation_queue_count() +
      "animation count: " + animation_count +
      "ajax connection count: " + ajax_connection_count;

    console.log(msg); // eslint-disable-line no-console
  }
  return {
    prevent_loading_me_in_ajax: prevent_loading_me_in_ajax,
    am_I_resting:am_I_resting,
    debug:debug_resting
  };
}());

$(document).ready(function() {
  Application.prevent_loading_me_in_ajax();
  // Uncomment this to see when application is busy VIA console
  // setInterval(function() {console.log(Application.am_I_resting())}, 400);

  //enables bootstrap templates just add data-toggle='tooltip' and a title to any page.
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  });

  $('table#user_management_table').DataTable({
    'aaSorting': [[ 0, 'desc' ]],
    'bPaginate': true,
    "pageLength": 50,
    'bLengthChange': false,
    'bFilter': true,
    'bSort': true,
    'bInfo': false,
    'bAutoWidth': false,
  });

  $('input#user_access_locked').bootstrapToggle({
    'on': 'Yes',
    'off': 'No',
    'labelText': '',
    'onstyle': 'primary',
  });


});
