import $ from 'jquery';

// Makes checkboxes act like radio buttons, allowing only one to be checked at a time.
// Allows exceptions by specifying compatibility with other checkboxes using data-compatible-with
// For example:
//   <div class="field checkbox-act-as-radio-buttons">
//     <%= f.check_box :asap_user %>
//     <%= f.check_box :hl7_user, data: { compatible_with: ["enhanced_auth"], } %>
//     <%= f.check_box :enhanced_auth, data: { compatible_with: ["hl7_user"], } %>
//   </div>
// would allow hl7_user and enhanced_auth to be checked at the time time, but
// asap_user could only be checked by itself.

export function CheckBoxSingleSelect(checkboxContainer) {
  var isCompatible = function(checkbox, compatibility) {
    var current_attr = checkbox && checkbox.name.match(/\[(.+)\]/);
    return compatibility && current_attr && compatibility.indexOf(current_attr[1]) != -1;
  };

  var clearAllCheckboxesExceptWithIdOrCompatibility = function(checkboxes, id_of_checkbox, compatibility) {
    var btn_test_user = 'button#btn_test_california_pmp_authentication';

    checkboxes.each(function() {
      // if not the checkbox that trigged this event or compatible, then uncheck it.
      if ($(this).attr('id') != id_of_checkbox && !isCompatible(this, compatibility)) {
        $(this).prop('checked', false);
      }

      if (id_of_checkbox === 'web_service_user_california_pmp_user') {
        $(btn_test_user).css('display', 'block');
      } else {
        $(btn_test_user).css('display', 'none');
      }
    });
  };

  // For each checkbox in the container attach a click a event.
  var init = function(checkboxContainer) {
    var checkboxes = $(checkboxContainer).find("input[type='checkbox']");

    checkboxes.each(function() {
      $(this).click(function() {
        clearAllCheckboxesExceptWithIdOrCompatibility(
          checkboxes,
          $(this).attr('id'),
          $(this).data('compatible-with')
        );
      });
    });
  }

  init(checkboxContainer);
}

function btnLock(itemId) {
  var btnLock = `<button type="button" class="btn btn-danger btn-xs lock_wsu"`;
  var result = btnLock + "data-item-id=" + itemId + " data-item-locked='false'>";
  result = result + " <span class='glyphicon glyphicon-remove-sign'></span> lock </button>";

  return result;
}

function btnUnlock(itemId) {
  var now = new Date();
  var locked_at = now.toISOString();
  var locked_date = locked_at.substring(0, 10);
  var locked_time = locked_at.substring(11, 16);

  var btnLock = `<button type="button" class="btn btn-success btn-xs lock_wsu"`;
  var result = btnLock + "data-item-id=" + itemId + " data-item-locked='true'>";
  result = result + " <span class='glyphicon glyphicon-ok-sign'></span> unlock </button>";
  result = result + " <span class='label label-warning'>";
  result = result + "Locked at: " + locked_date + " " + locked_time + "</span>";

  return result;
}

$(() => {
  $('#web-service-users').DataTable( {
    'bPaginate': true,
    'pageLength': 10,
    'bLengthChange': true,
    'bFilter': true,
    'bSort': false,
    'bInfo': true,
    'bAutoWidth': false,
    'bServerSide': true,
    'sAjaxSource': $('#web-service-users').data('source'),
    'columns': [
      {'orderable': false, 'searchable': false, 'width': '20%'},
      {'orderable': false, 'searchable': false, 'width': '30%'},
      {'orderable': false, 'searchable': false, 'width': '10%'},
      {'orderable': false, 'searchable': false, 'width': '10%'},
      {'orderable': false, 'searchable': false, 'width': '10%'},
      {'orderable': false, 'searchable': false, 'width': '10%'},
    ],
  });

  $(document).on('click', '.lock_wsu', function (){
    var cell = this.parentElement;
    var itemId = this.dataset.itemId;
    var locked = this.dataset.itemLocked;

    if (locked === 'false') {
      if (!confirm("Are you sure you want to lock user?")) return;

      $.ajax(
        {
          url: "/web_service_users/" + itemId + "/lock_user",
          timeout: 20000,
          type: "PATCH",
          success: function() {
            cell.innerHTML = btnUnlock(itemId);
          },
        }
      );
    }

    if (locked === 'true') {
      if (!confirm("Are you sure you want to unlock user?")) return;

      $.ajax(
        {
          url: "/web_service_users/" + itemId + "/unlock_user",
          timeout: 20000,
          type: "PATCH",
          success: function() {
            cell.innerHTML = btnLock(itemId);
          },
        }
      );
    }
  });
});
